












import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class Connect extends Vue {
  private appUrl = process.env.VUE_APP_BASE_URL;
  private oAuthLink =
    `https://www.strava.com/oauth/authorize?client_id=69124&response_type=code&approval_prompt=force&scope=activity:read_all&redirect_uri=${this.appUrl}/oauth`;
}
